import * as React from "react"
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar } from '@themesberg/react-bootstrap';
import { faAngleDown, faAngleUp, faChartArea, faChartBar, faChartLine, faFlagUsa, faFolderOpen, faGlobeEurope, faPaperclip, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faAngular, faBootstrap, faReact, faVuejs } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



export default ({ cover, avatar, title, text, subtitle  }) => {
    return     <Card border="light" className="text-center p-0 mb-4">
    <div style={{ backgroundImage: `url(${cover})` }} className="profile-cover rounded-top" />
    <Card.Body className="pb-5">
      {
        avatar && <Card.Img src={avatar} alt="Neil Portrait" className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4" />
      }
      <Card.Title>{ title }</Card.Title>
      <Card.Subtitle className="fw-normal"> { subtitle }</Card.Subtitle>
      <Card.Text className="text-gray mb-4">{ text }</Card.Text>

      <Button variant="primary" size="sm" className="me-2">
        <FontAwesomeIcon icon={faUserPlus} className="me-1" /> Connect
      </Button>
      <Button variant="secondary" size="sm">Send Message</Button>
    </Card.Body>
  </Card>
}