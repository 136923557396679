import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Form, Button, InputGroup, Breadcrumb, Table } from '@themesberg/react-bootstrap';
import Grid from '../components/Grid'
import { navigate, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHome, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield, faAngleDown, faEye } from "@fortawesome/free-solid-svg-icons";
import Parse from '../vendor/Parse'
import { useLocation } from '@reach/router';
import NumberTable from '../components/NumberTable'
import { Modal } from '@themesberg/react-bootstrap';
import useAuth from "../hooks/userAuth";


const IndexPage = () => {

  return <Layout>
    <h3>
      Welcome to OpenFengShui. To Look For Mobile Number. Please click <Link to="/numberFinder">here</Link>
    </h3>

  </Layout >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
